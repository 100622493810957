import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listBalai: [],
      listStatker: [],
      list_usersTypes: [],
      list_userGroup: [],
      isBalai:false,
      isSatker:false

    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable()

    this.G_GetRef('balai').then(data => {
      this.listBalai = data;
    });

    this.G_GetRef('userType').then(data => {
      this.list_usersTypes = data;
    });

    this.G_GetRef('userGroup').then(data => {
      this.list_userGroup = data;
    });

   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {
      this.loadingTb = true
      this.G_GetAny('sakti/event').then(res => {
        console.log(res);
        this.loadingTb = false
        this.tbData = res.data.content
      })
    },
    edit(id) {
      console.log(id);
      this.G_GetAny('sakti/event/' + id).then(res => {
        this.getData = res.data
        this.setId = res.data.saktiEventId
      

        this.$refs.modal.open("Edit Data Event", 600)

        this.$nextTick(() => {

        });
      })
    },
    add() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
      this.setId = null
      this.getData = []
      this.$refs.modal.open("Tambah Data Event", 600)

    },
    store() {
      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);
    
      if (this.setId) {
        formData.append('saktiEventId',this.setId)
        formData.append('visible',this.getData.visible)
        formData.append('asDefault',this.getData.asDefault)
      }

      var object = {};
      formData.forEach((value, key) => object[key] = value);


      if (this.$refs.form.validate()) {
          this.$refs.modal.loading()

          if (this.setId) {
            this.G_PutAnyCustom('sakti/event', object).then(res => {
              console.log(res);
              if (!res.status) {
                this.getTable();
                this.$snotify.success('Berhasil Mengubah Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }else{
            this.G_PostAnyCustom('sakti/event', object).then(res => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success('Berhasil Menyimpan Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }

         
      }
      else {
        this.$snotify.info('Lengkapi Data Terlebih Dahulu');
      }
    },
  }
}